import { render, staticRenderFns } from "./FileConfig.vue?vue&type=template&id=274427fa&scoped=true&"
import script from "./FileConfig.vue?vue&type=script&lang=js&"
export * from "./FileConfig.vue?vue&type=script&lang=js&"
import style0 from "./FileConfig.vue?vue&type=style&index=0&id=274427fa&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "274427fa",
  null
  
)

export default component.exports